import styled from "styled-components"
import Page from "../../components/Pages/Page"
import React, { useState } from "react"
import colors from "../../colors"
import { MdMailOutline } from "react-icons/md"
import axios from "axios"
import { API_URL } from "../../constants"
import { getQueryParam } from "../../utils/query"
import AllNews from "../../components/News/AllNews"

const News = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  align-items: center;
  padding: 0px 5%;
  width: 70%;
  align-self: center;

  @media (max-width: 980px) {
    width: 100%;
  }
  @media (max-width: 425px) {
    padding: 0px;
  }

  h1 {
    font-family: "Poppins-Bold";
    @media (max-width: 425px) {
      font-size: 1.5rem;
    }
  }
  img {
    height: 70px;
    width: 70px;
    margin-bottom: 10px;
  }
  button,
  .button {
    background-color: ${colors.primary2};
    padding: 10px 30px;
    border-radius: 8px;
    border: none;
    color: ${colors.white};
    font-family: "Poppins-Bold";
    margin-top: 12px;
    margin-bottom: 5px;
    cursor: pointer;
    width: 100%;
  }
  .input {
    color: ${colors.primary2};
    margin-top: 12px;
    padding: 10px 20px;
    border: 2px solid ${colors.primary2};
    border-radius: 8px;
    display: flex;
    align-items: center;
    width: 100%;
  }
  .icon {
    color: ${colors.primary2};
    font-size: 1.3rem;
    margin-right: 8px;
  }

  input {
    border: none;
    color: ${colors.primary2};
    width: 100%;
  }
  form {
    width: 100%;
  }
  textarea:focus,
  input:focus {
    outline: none;
  }
`
const Newsletter = () => {
  const [email, setEmail] = useState(null)
  const [error, setError] = useState(null)

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email)
  }

  const handleSubmit = async () => {
    setError(null)
    if (!email) {
      return setError("Informe o seu email")
    }
    if (!validateEmail(email)) {
      return setError("Informe um email válido")
    }
    try {
      const { data } = await axios.post(API_URL + `/newsletter`, {
        email,
        createdAt: new Date().toISOString(),
        utm_source: getQueryParam("utm_source"),
        utm_medium: getQueryParam("utm_medium"),
        utm_campaign: getQueryParam("utm_campaign"),
      })
      if (data.success) {
        setEmail(null)
        setError(null)
      }
    } catch (e) {
      // setError(e.response.data.message)
    }
  }
  return (
    <Page
      // hideHeader
      // hideFooter
      title={`Newsletter`}
      description={`Uma newsletter para tornar mais claro o seu caminho para o sucesso e desenvolvimento pessoal por meio de conteúdos sobre hábitos, produtividade e metas.`}
      pageId={"newsletter"}
      forceTheme={"default"}
    >
      <News>
        <img src={require("../../assets/images/logo.png")} alt="logo" />
        <h1> 🧠 Pílulas do Flynow 🧠</h1>
        <br />
        <p>
          Uma newsletter para tornar mais claro o seu{" "}
          <b>caminho para o sucesso</b> e<b> desenvolvimento pessoal</b> por
          meio de conteúdos sobre <b>hábitos, produtividade e metas.</b>
          <br />
          <br />
          👉 Preencha seu e-mail e <b>cadastre-se gratuitamente</b> agora mesmo:
        </p>

        <form
          onSubmit={handleSubmit}
          action="https://novo.nitronews.com.br/formularios/confirmacao-formularioHTML"
          method="get"
          // target="_blank"
          id="formIntegracaoHTML"
        >
          <div className="input">
            <MdMailOutline className="icon" />
            <input
              type="email"
              name="email%%E-mail"
              id="email"
              required
              placeholder="Seu melhor e-mail"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>

          <input type="hidden" name="chave[]" value="-IAr" />
          <input type="hidden" name="cliente" value="App Flynow" />
          <input
            type="hidden"
            name="urlretorno"
            value="https://appflynow.com/newsletter/subscribed"
          />
          <input
            type="submit"
            name="enviar"
            value="CADASTRAR (GRÁTIS)"
            className="button"
          />
        </form>

        {error && <p style={{ color: "red" }}>{error}</p>}

        <br/>
        <br/>
        <br/>
        <br/>
        <AllNews />
      </News>
    </Page>
  )
}

export default Newsletter
